.layout-collapse-demo {
    min-height: 100vh;
    border: 1px solid var(--color-border);
    background: var(--color-fill-2);
}

.layout-collapse-demo .arco-layout-sider .logo {
    height: 32px;
    margin: 12px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
}

.layout-collapse-demo .arco-layout-sider-light .logo {
    background: var(--color-fill-2);
}

.layout-collapse-demo .arco-layout-footer,
.layout-collapse-demo .arco-layout-content {
    color: var(--color-white);
    text-align: center;
    font-stretch: condensed;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: top;
}

.layout-collapse-demo .arco-layout-footer {
    color: var(--color-text-2);
    height: 48px;
    line-height: 48px;
    font-weight: 400;
    font-size: 14px;
}

.layout-collapse-demo .arco-layout-content {
    background: var(--color-bg-3);
    color: var(--color-text-2);
    font-weight: 400;
    font-size: 14px;
}

.layout-collapse-demo .arco-layout-header {
    height: 64px;
    line-height: 64px;
    background: var(--color-bg-3);
}

.layout-collapse-demo .arco-layout-header .trigger {
    margin-left: 20px;
}

.arco-table-custom-filter {
    padding: 10px;
    background-color: var(--color-bg-5);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }